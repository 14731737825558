import React, { useEffect, useState } from 'react'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '100vh'
};

const center = {
  lat: 19.42847, // Ejemplo: coordenadas de Ciudad de Mexico
  lng:  -99.12766
};



export const MapForCrop = () => {
	const [markerPosition, setMarkerPosition] = useState();

  // Manejador de clic en el mapa
  const onMapClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setMarkerPosition({ lat, lng }); // Actualizar la posición del marcador
    // Aquí puedes llamar la API de Geocoding con las coordenadas obtenidas
    console.log(`Coordenadas: Latitud: ${lat}, Longitud: ${lng}`);
  };
	 
  return (
	
	<LoadScript googleMapsApiKey={process.env.REACT_APP_MAPS_API_KEY}>
      <GoogleMap
         mapContainerStyle={containerStyle}
		 center={center}
		 zoom={10}
		 onClick={onMapClick} // Escuchar el clic en el mapa
      >
        <Marker position={markerPosition} />
      </GoogleMap>
    </LoadScript>
  )
}
