import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { UserContext } from '../../../../context/UserProvider';
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import HeadTile from './HeadTile';
import { db } from '../../../../config/firebase';
import { HIGHCHART_OPT_SPANISH } from '../../../../constants/globalConst';
import { get_Uid_Value_ForAnalog, getDataFromDB } from '../../../../context/functions/DashboardFunctions/divideUIDByKind';

const useStyles = makeStyles({
	paperGraphics: {
	  width: "215px",
	  height: "170px",
	  padding: "5px",
	  display: "flex",
	  alignItems: "center",
	  justifycontent: "center",
	  background: "transparent",
	},
	minmaxText: {
	  color: "#ACACAC",
	  fontSize: "8px",
	},
	noGraphText: {
	  color: "#ACACAC",
	},
	titleGraph: {
	  color: "#000",
	  fontSize: "30px",
	},
	subtitleGraph: {
	  color: "#5BB033",
	  fontSize: "20px",
	},
});

export const NodeLoraWanTile = ({ data, style, col }) => {
	const { usuario, dataMacCan, getTimezoneOffsetInSeconds } = useContext(UserContext);
	const classes = useStyles();
	const uid = data.uid;
	const arrayDeCadenas = data.uid.split("@"); //0@1@2@3@4
	const [nodeName, setNodeName] = useState("Nodo");
	const [value, setValue] = useState(null);
	const [minVal, setMinVal] = useState(Infinity);
  	const [maxVal, setMaxVal] = useState(-Infinity);
	const [prevValue, setPrevValue] = useState(null);
  	const [valArrayChart, setValArrayChart] = useState([]);
	const [isDataFlag, setIsDataFlag] = useState(false);

	const useDocumentListener = (docPath) => {
		useEffect(() => {
		  // Obtiene la referencia al documento
		  const docRef = db.doc(docPath);
	  
		  // Se suscribe a los cambios del documento
		  const unsubscribe = docRef.onSnapshot((docSnapshot) => {
			if (docSnapshot.exists) {
			  data = docSnapshot.data();
			//   console.log("Esto es data:",data)
			  const allData = data.data
			  for (let index = 0; index < allData.length; index++) {
				if(Number(arrayDeCadenas[3]) === allData[index].id){
					if (arrayDeCadenas[4] === "temp") {
						setValue(allData[index].temperature)
					} else if (arrayDeCadenas[4] === "hum") {
						setValue(allData[index].humidity)
					} else if (arrayDeCadenas[4] === "co2") {
						setValue(allData[index].co2)
					} else if (arrayDeCadenas[4] === "pres") {
						setValue(allData[index].pressure)
					} else if (arrayDeCadenas[4] === "ec") {
						setValue(allData[index].conductivity)
					} else if (arrayDeCadenas[4] === "ph") {
						setValue(allData[index].ph)
					} else if (arrayDeCadenas[4] === "nit") {
						setValue(allData[index].nitrogen)
					} else if (arrayDeCadenas[4] === "phos") {
						setValue(allData[index].phosphorus)
					} else if (arrayDeCadenas[4] === "pot") {
						setValue(allData[index].potassium)
					} else if (arrayDeCadenas[4] === "par") {
						setValue(allData[index].photosynthetic)
					} else if (arrayDeCadenas[4] === "wind") {
						setValue(allData[index].windSpeed)
					} else if (arrayDeCadenas[4] === "rain") {
						setValue(allData[index].rainGauge)
					}
				}
				
			  }
			  
			  // console.log("Esto es data:", data);
			} else {
			  console.log("¡No se encontró el documento!");
			}
		  }, (err) => {
			console.error("Error al escuchar el documento: ", err);
		  });
	  
		  // Función de limpieza que se ejecuta cuando el componente se desmonta
		  return () => unsubscribe();
	  
		}, [docPath]); // El efecto se vuelve a ejecutar si docPath cambia
	};
  
	const docPath = `${usuario.username}/loraDevices/nodes/${arrayDeCadenas[2]}/sensors/dataSensors`;
  
	useDocumentListener(docPath);
	const isMountedRef = useRef(null);

	useEffect(() => {
		isMountedRef.current = true; // Indicar que el componente está montado
		const obtenerDatos = async () => {
			try {
			  const arrayDeCadenas = uid.split("@");
			  const nodeid = arrayDeCadenas[2];
			  const addr2 = `${usuario.username}/loraDevices/nodes/${nodeid}/sensors`;

			  const leerConfigNode = async () => {
				try {
				  const docRef = db.collection(addr2).doc("registeredSensors");
				  const docSnap = await docRef.get();
			  
				  if (docSnap.exists) {
					const allDataSensors = docSnap.data().sensors;
					const idSensor = uid.split("@")[3];
					let assignName = ''
					for (let index = 0; index < allDataSensors.length; index++) {
						if(Number(idSensor) === allDataSensors[index].id ) {
							if (arrayDeCadenas[4] === "temp") {
								assignName = `${allDataSensors[index].name}-Temp.`
							} else if (arrayDeCadenas[4] === "hum") {
								assignName = `${allDataSensors[index].name}-Hum.`
							} else if (arrayDeCadenas[4] === "co2") {
								assignName = `${allDataSensors[index].name}-CO2`
							} else if (arrayDeCadenas[4] === "pres") {
								assignName = `${allDataSensors[index].name}-Pres.`
							} else if (arrayDeCadenas[4] === "ec") {
								assignName = `${allDataSensors[index].name}-EC`
							} else if (arrayDeCadenas[4] === "ph") {
								assignName = `${allDataSensors[index].name}-pH`
							} else if (arrayDeCadenas[4] === "nit") {
								assignName = `${allDataSensors[index].name}-Nit.`
							} else if (arrayDeCadenas[4] === "phos") {
								assignName = `${allDataSensors[index].name}-Phos.`
							} else if (arrayDeCadenas[4] === "pot") {
								assignName = `${allDataSensors[index].name}-Pot.`
							} else if (arrayDeCadenas[4] === "par") {
								assignName = `${allDataSensors[index].name}-PAR`
							} else if (arrayDeCadenas[4] === "wind") {
								assignName = `${allDataSensors[index].name}-WindS`
							} else if (arrayDeCadenas[4] === "rain") {
								assignName = `${allDataSensors[index].name}-RainG`
							}
							setNodeName(assignName)
							
						}
					}
					// Solo actualizar si el componente aún está montado
					if (isMountedRef.current) {
						
					}
					
				  } else {
					// El documento no existe.
					if (isMountedRef.current) {
					  console.log("No se encontró nombre del Nodo Lora");
					}
					
				  }
	
				} catch (error) {
				  console.error("Error al obtener el documento:", error);
				}
			  };
			  
			  //leerDatosDB();
			  leerConfigNode();
			} catch (error) {
			  console.log("obtenerDatos: error ", error);
			}
		  };
		  obtenerDatos();
		  return () => {
			isMountedRef.current = false; // Indicar que el componente se ha desmontado
		  };

	}, [uid, usuario.username])

	useEffect(() => {
		Highcharts.setOptions(HIGHCHART_OPT_SPANISH);
	
		const fetchAllData = async () => {
			try {
				const results = await getDataFromDB(uid, usuario.username);
				setValArrayChart([...results.valArray]);
				setMinVal(results.minVal);
				setMaxVal(results.maxVal);
			} catch (err) {
				console.error({ err });
				setValArrayChart([]);
				setMinVal(Infinity);
				setMaxVal(-Infinity);
			}
		};
	
		fetchAllData();
	}, [uid, usuario.username]);

	const updateChartData = useCallback((newValue) => {
		let newData = [...valArrayChart, [Math.floor(Date.now()), newValue]];
		const limit = 20;
		if (newData.length > limit) {
			newData = newData.slice(newData.length - limit);
		}
		setValArrayChart(newData);
	}, [valArrayChart]);


	// useEffect(() => {
	// 	if(dataMacCan.length > 0) {
	// 	  const docPath = `${usuario.username}/loraDevices/nodes/${uid.split("@")[2]}/sensors/dataSensors`;
	// 	  const renderDoc = db.doc(docPath);
	  
	// 	  const unsubscribe = renderDoc.onSnapshot((docSnapshot) => {
	// 		  const data = docSnapshot.data();
	// 		  const resp = get_Uid_Value_ForAnalog({
	// 			  fromModuleActual: data,
	// 			  kind: uid.split("@")[2],
	// 			  outid: uid.split("@")[3],
	// 			  typeOfModule: dataMacCan.find((elmt) => elmt.mac === uid.split("@")[0]).cans.find((elmt) => elmt.id === uid.split("@")[1]).type,
	// 		  });
	  
	// 		  if(resp.value === "-1.0") {
	// 			setIsDataFlag(true);
	// 			setPrevValue(resp.value);
	// 		  }else if (resp.value !== prevValue && resp.value >= 0.1) {
	// 			setIsDataFlag(false);
	// 			setValue(resp.value);
	// 			setPrevValue(resp.value);
	// 			updateChartData(parseFloat(resp.value));
	// 		  }
	// 	  });
	  
	// 	  return () => unsubscribe();
	// 	}
	//   }, [uid, usuario.username, dataMacCan, prevValue, updateChartData]);
	
	
    
  return (
	<Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
      style={style}
    >
		<HeadTile name={nodeName} uid={uid} col={col} />
		<div style={{ marginTop: '20px' }}>
			<Grid item xs={5}>
				{isDataFlag ? "Sensor desconectado" :
				<h5><span className="badge badge-dark">{ value }</span></h5>
				}
			</Grid>
		</div>
		
		{valArrayChart.length > 1 ? (
        <Paper className={classes.paperGraphics} elevation={0}>
          <Grid item xs={8}>
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                time: {
                    // timezoneOffset: new Date().getTimezoneOffset(),
                    timezoneOffset: getTimezoneOffsetInSeconds(),
                },
                chart: {
                    height: "150px",
                    type: "area",
                    backgroundColor: "transparent",
                    borderRadius: 10,
                },
                series: [{
                  data: valArrayChart,
                  label: nodeName,
                  name: nodeName,
                  type: 'area',
                  color: '#5BB033',
                  fillColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                    stops: [
                      [0, Highcharts.color("#5BB033").setOpacity(0.5).get("rgba")],
                      [1, Highcharts.color("#5BB033").setOpacity(0).get("rgba")]
                    ]
                  }
                }],
                title: {
                    text: "",
                    margin: 0,
                },
                xAxis: {
                    type: "datetime",
                    lineColor: "transparent",
                    labels: {
                      enabled: false,
                    },
                },
                yAxis: [
                    {
                      gridLineColor: "transparent",
                      title: {
                        align: "high",
                        offset: 0,
                        text: "",
                        rotation: 0,
                        y: -10,
                      },
                      labels: {
                        enabled: false,
                      },
                    },
                    {
                      gridLineColor: "transparent",
                      title: {
                        align: "high",
                        offset: 0,
                        text: "",
                        rotation: 0,
                        y: -10,
                      },
                      labels: {
                        enabled: false,
                      },
                    },
                  ],
                  legend: {
                    enabled: false,
                  },
                  credits: {
                    enabled: false,
                  },
                // Otras opciones de Highcharts...
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Grid container direction="column" justifycontent="space-between" alignItems="center" spacing={8}>
              <Grid item xs={12}>
                <Typography variant={"inherit"} className={classes.minmaxText}>{maxVal}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant={"inherit"} className={classes.minmaxText}>{minVal}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      ) : (
        <Grid item xs={12}>
          <Paper className={classes.paperGraphics} elevation={0}>
            <Typography variant={"h6"} className={classes.noGraphText}>
              Sin Gráfica
            </Typography>
          </Paper>
        </Grid>
      )}

	</Grid>
  )
}
