import React, { useEffect, useRef, useState } from 'react'
import { Card, CardContent, Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import { GoogleMap, LoadScript, DrawingManager } from '@react-google-maps/api';
import { SelectCropField } from './SelectCropField';

const useStyles = makeStyles((theme) => ({
	root: {
	  width: '100%',
	  padding: theme.spacing(2),
	  boxSizing: 'border-box',
	  boxShadow: "0px 0px 4px black", //offset-x | offset-y | blur-radius | color
	  [theme.breakpoints.down('xs')]: {
		width: '85vw', // Para pantallas pequeñas, ocupa más espacio
	  },
	},
  sideCard: {
	  width: '100%',
	  //padding: theme.spacing(1),
	  boxSizing: 'border-box',
	  boxShadow: "0px 0px 1px black", //offset-x | offset-y | blur-radius | color
	  [theme.breakpoints.down('xs')]: {
		width: '85vw', // Para pantallas pequeñas, ocupa más espacio
	  },
	},
	title: {
	  fontWeight: 'bold',
	},
	form: {
	  marginTop: theme.spacing(2),
	},
	button: {
	  marginTop: theme.spacing(2),
	},
}));

const containerStyle = {
  width: '100%',
  height: '60vh'
};

const center = {
  lat: 19.42847,
  lng: -99.12766
};



export const SatelliteAnalysis = () => {
  const classes = useStyles();
  const [map, setMap] = useState(null); // Guardamos la referencia al mapa una vez cargado
  const [drawPolygon, setDrawPolygon] = useState(false)
  const [geoJsonData, setGeoJsonData] = useState(null);
  const [drawingMode, setDrawingMode] = useState(null);
  const [namesOfPolygons, setNamesOfPolygons] = useState([]);
  const [selectedPolygon, setSelectedPolygon] = useState(null);

  const onLoadMap = (mapInstance) => {
    setMap(mapInstance); // Guarda la referencia al mapa cuando se carga
  };

  const onPolygonComplete = (polygon) => {
    if (window.google && window.google.maps) {
      // Hacer que el polígono sea editable
      polygon.setEditable(true);

      
      const path = polygon.getPath();
      const area = window.google.maps.geometry.spherical.computeArea(path);
      console.log('Área del polígono:', area, 'm²');
      // Desactivar el modo de dibujo
      setDrawingMode(null);

      // Añadir listeners para manejar cambios en el polígono (opcional)
      window.google.maps.event.addListener(polygon.getPath(), 'set_at', () => {
        console.log('Vértice modificado o agregado');
      });
      window.google.maps.event.addListener(polygon.getPath(), 'remove_at', () => {
        console.log('Vértice eliminado');
      });

      const coordinates = path.getArray().map(coord => ({
        lat: coord.lat(),
        lng: coord.lng(),
      }));

      console.log('Coordenadas del polígono:', coordinates);
    }
  };

  const processPoints = (geometry, callback, thisArg) => {
    if (geometry instanceof window.google.maps.LatLng) {
      callback.call(thisArg, geometry);
    } else if (geometry instanceof window.google.maps.Data.Point) {
      callback.call(thisArg, geometry.get());
    } else {
      geometry.getArray().forEach((g) => {
        processPoints(g, callback, thisArg);
      });
    }
  };

  useEffect(() => {
    if(geoJsonData !== null) {
      const fileDataArray = geoJsonData.features;
      const arrayOfNames = [];
      if (map) {
        map.data.addGeoJson(geoJsonData);

        // Ajustar los límites del mapa
        const bounds = new window.google.maps.LatLngBounds();
        map.data.forEach(function (feature) {
          processPoints(feature.getGeometry(), bounds.extend, bounds);
        });
        map.fitBounds(bounds);
      }
      fileDataArray.forEach((item) => {
        if (item.geometry.type === "Polygon") {
          arrayOfNames.push(item.properties.name)
        }
      });
      
    }
  }, [geoJsonData,map])

  useEffect(() => {
    if(selectedPolygon) {
      const selectedFeature = geoJsonData.features.find(
        (feature) => feature.properties.name === selectedPolygon
      );
      
      if (selectedFeature) {
        // Obtener las coordenadas del centro del polígono
        const bounds = new window.google.maps.LatLngBounds();
        selectedFeature.geometry.coordinates[0].forEach(([lng, lat]) => {
          bounds.extend({ lat, lng });
        });
        map.fitBounds(bounds);
      }
    }
  }, [selectedPolygon])
  
  

  return (
	<div>
    <Card className={classes.root}>
        	<CardContent>
				<Typography variant="h6" className={classes.title}>
					Monitoreo de cultivo en exterior
				</Typography>

				<div style={{ marginBottom: "15px", marginTop: "10px"}}>
					<Divider />
				</div>
        <div>
          <Grid container direction="row" justifyContent='center' spacing={1}>
            <Grid item xs={9}>
              <LoadScript
              googleMapsApiKey={process.env.REACT_APP_MAPS_API_KEY}
              libraries={['drawing', 'geometry']}
              >
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={center}
                  zoom={10}
                  onLoad={onLoadMap} // Se ejecuta cuando el mapa se ha cargado
                  options={{
                    gestureHandling: 'greedy',
                  }}
                >
                  {(map && drawPolygon) && (
                    <DrawingManager
                      onPolygonComplete={onPolygonComplete} // Escucha cuando un polígono se completa
                      options={{
                        drawingControl: false,
                        drawingMode: drawingMode,
                        polygonOptions: {
                          fillColor: '#2196F3',
                          strokeColor: 'green',
                          fillOpacity: 0.5,
                          strokeWeight: 2,
                          clickable: true,
                          editable: true,
                          zIndex: 1,
                          //draggable: false,
                        },
                      }}
                    />
                  )}
                </GoogleMap>
              </LoadScript>
            </Grid>

            <Grid item xs={3}>
              <Card className={classes.sideCard}>
                <CardContent>
                  <SelectCropField 
                  setGeoJsonData={setGeoJsonData} 
                  setDrawPolygon={setDrawPolygon} 
                  setDrawingMode={setDrawingMode}
                  setSelectedPolygon={setSelectedPolygon}
                  />
                </CardContent>
              </Card>
            </Grid>
        
          </Grid>
        </div>			

        	</CardContent>
      	</Card>
  </div>
  )
}
