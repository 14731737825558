import React from "react";
import { db, functions } from "../../../config/firebase";
import { UserContext } from "../../../context/UserProvider";
import { GraphicDataDevices } from "../../Common";
import { ALL_KINDS } from "../../../constants/globalConst";

const Graph = () => {
  const { usuario, dataMacCan } = React.useContext(UserContext);
  const [outDevices, setOutDevices] = React.useState([]);
  const [inputDevices, setInputDevices] = React.useState([]);
  const [manualDevices, setmanualDevices] = React.useState([]);
  const [sensorsManualName, setSensorsManualName] = React.useState([]);

  const arrayLoraNames = ["TempAmb", "HumAmb", "CO2Amb", "HumSoil", "TempSoil", "EcSoil", "PhSoil"];
  const arrayLoraKinds = ["50", "51", "52", "53", "54", "55", "56"];

  const getNamesOfManualSensors = async () => {
    try {
      const getSubCollections = functions.httpsCallable("getSubCollections");
      const listColl = await getSubCollections({
        docPath: `${usuario.username}/manualMeasurements`,
      });

      const clone = listColl.data.collections.map((item) => ({
        uid: item,
        value: item,
        label: item,
      }));
      console.log("[getNamesOfManualSensors] names:", clone);
      return clone;
    } catch (error) {
      console.log(error);
    }
  };

  const getLoraNodesData = async () => {
    try {
      const addr = usuario.username + "/loraDevices/nodes";
      const data = await db.collection(addr).get();
      
      // Verificar si la colección está vacía
      if (data.empty) {
        return "No Lora Devices";
      }
      
      // Si la colección no está vacía, extraer los nombres de los documentos
      const dataConfig = data.docs.map((doc) => doc.id);
       let sensorsUid = [];
       for (let index = 0; index < dataConfig.length; index++) {
        //console.log("Esto es dataConfig[index]:",dataConfig[index]);
         const configAddr = `${usuario.username}/loraDevices/nodes/${dataConfig[index]}/configNode`;
         const sensorsLWAddr = `${usuario.username}/loraDevices/nodes/${dataConfig[index]}/sensors`;
         const data = await db.collection(configAddr).doc("renderData").get();
         if(data.exists) {
          const dataLora = data.data();
          //  console.log("Esto es dataLora:",dataLora);
           const uid = dataLora.item.uid;
           const generalName = dataLora.item.nodeName;
           const arrayDeCadenas = uid.split("@");
           const mac = arrayDeCadenas[0];
           const outId = arrayDeCadenas[2];
           for (let index = 0; index < arrayLoraKinds.length; index++) {
             const id = mac + "@0@" + arrayLoraKinds[index] + "@" + outId;
             const nameToShow = generalName + "-" + arrayLoraNames[index];
             const item = {mac: mac, name: nameToShow}
             const dataToSave = {id: id, item: item}
             sensorsUid = [...sensorsUid, dataToSave];
           }

         } else {
          const dataLoraWan = await db.collection(sensorsLWAddr).doc("registeredSensors").get();
          if(dataLoraWan.exists){
            const parametersOfSensors = await db.collection(sensorsLWAddr).doc("dataSensors").get();
            const dataRecordedFromSensors = dataLoraWan.data().sensors;
            for (let index = 0; index < dataRecordedFromSensors.length; index++) {
              const element = dataRecordedFromSensors[index];
              
            }
          }
         }               
        
         
       }
      return sensorsUid;
    } catch (error) {
      // Manejo de errores, por ejemplo, si la ruta de la colección no existe
      console.error("Error accediendo a la colección:", error);
      return "No Lora Devices";
    }
  }

  const typeSensor = (measure) => {
    let input = [];
    let output = [];
    let uidSplited = [];

    for (let s of measure) {
      uidSplited = s.id.split("@");

      if (uidSplited[2] === ALL_KINDS.IN_RELOJ) {
      } else if (
        uidSplited[2] === ALL_KINDS.OUT_AC ||
        uidSplited[2] === ALL_KINDS.OUT_PUMB_DC ||
        uidSplited[2] === ALL_KINDS.OUT_PWM
      )
        output.push({ uid: s.id, label: s.item.name, value: s.item.name });
      else input.push({ uid: s.id, label: s.item.name, value: s.item.name });
    }
    const cloneManual = [...sensorsManualName];
    setOutDevices(output);
    setInputDevices(input);
    setmanualDevices(cloneManual);
  };

  React.useEffect(() => {
    const obtenerDatos = async () => {
      let dataConfiguracion = [];
      try {
        let localDate = JSON.parse(localStorage.getItem("localDate"));
        if (localDate !== null) {
          console.log(
            " Diff null localDate Graph: ",
            JSON.parse(localStorage.getItem("localDate"))
          );
          if (
            (Date.now() - new Date(localDate.date) >= 900000 &&
              !localDate.flgGraph) ||
            localDate.flgGraph === null ||
            localDate.flgGraph === undefined
          ) {
            console.log("Actualizo info Graficas Local");
            if (dataMacCan.length > 0) {
              await Promise.all(
                dataMacCan.map(async (itemMac) => {
                  const dataCan = itemMac.cans.map((Item) => Item.id);
                  await Promise.all(
                    dataCan.map(async (itemCan) => {
                      const addr =
                        usuario.username +
                        "/infoDevices/" +
                        itemMac.mac +
                        "/" +
                        itemCan +
                        "/configModule";
                      const data = await db.collection(addr).get();
                      const dataConfig = data.docs.map((doc) => ({
                        id: doc.id,
                        ...doc.data(),
                      }));
                      dataConfiguracion = [...dataConfiguracion, ...dataConfig];
                    })
                  );
                })
              );
              const sensorsNamesClone = await getNamesOfManualSensors();
              setSensorsManualName(sensorsNamesClone);

              localStorage.setItem(
                "sensorsNamesClone",
                JSON.stringify(sensorsNamesClone)
              );
              const dataLora = await getLoraNodesData();
              // console.log("Esto es dataLora:", dataLora);
              if (dataLora !== "No Lora Devices") {
                // const isLoraDevice = true;
                // console.log("DataConfiguracion antes:", dataConfiguracion);
                dataConfiguracion = [...dataConfiguracion, ...dataLora];
                // console.log("DataConfiguracion añadiendo Lora:", dataConfiguracion);
              }
              
              if (dataConfiguracion.length > 0) {
                typeSensor(dataConfiguracion, false);
                localStorage.setItem(
                  "dataConfiguracion",
                  JSON.stringify(dataConfiguracion)
                );
              }
              localStorage.setItem(
                "localDate",
                JSON.stringify({
                  ...localDate,
                  ...{ date: new Date().toISOString(), flgGraph: true },
                })
              );
            }         
          } else {
            console.log("Tomo información BD info Graficas");
            typeSensor(JSON.parse(localStorage.getItem("dataConfiguracion")), false);
            setSensorsManualName(
              JSON.parse(localStorage.getItem("sensorsNamesClone"))
            );
            localStorage.setItem(
              "localDate",
              JSON.stringify({
                ...localDate,
                flgGraph: false,
              })
            );
          }
        } else {
          console.log(
            "Equal null localDate Graph: ",
            JSON.parse(localStorage.getItem("localDate"))
          );
          if (dataMacCan.length > 0) {
            await Promise.all(
              dataMacCan.map(async (itemMac) => {
                const dataCan = itemMac.cans.map((Item) => Item.id);
                await Promise.all(
                  dataCan.map(async (itemCan) => {
                    const addr =
                      usuario.username +
                      "/infoDevices/" +
                      itemMac.mac +
                      "/" +
                      itemCan +
                      "/configModule";
                    const data = await db.collection(addr).get();
                    const dataConfig = data.docs.map((doc) => ({
                      id: doc.id,
                      ...doc.data(),
                    }));
                    dataConfiguracion = [...dataConfiguracion, ...dataConfig];
                  })
                );
              })
            );
            const sensorsNamesClone = await getNamesOfManualSensors();
            setSensorsManualName(sensorsNamesClone);

            localStorage.setItem(
              "sensorsNamesClone",
              JSON.stringify(sensorsNamesClone)
            );

            const dataLora = await getLoraNodesData();
            // console.log("Esto es dataLora:", dataLora);
            if (dataLora !== "No Lora Devices") {
              // const isLoraDevice = true;
              // console.log("DataConfiguracion antes:", dataConfiguracion);
              dataConfiguracion = [...dataConfiguracion, ...dataLora];
              // console.log("DataConfiguracion añadiendo Lora:", dataConfiguracion);
            }
            // console.log("Esto es dataConfiguracion:",dataConfiguracion);
            if (dataConfiguracion.length > 0) {
              typeSensor(dataConfiguracion, false);
              localStorage.setItem(
                "dataConfiguracion",
                JSON.stringify(dataConfiguracion)
              );
            }
            localStorage.setItem(
              "localDate",
              JSON.stringify({
                ...{ date: new Date().toISOString(), flgGraph: true },
                ...localDate,
              })
            );
          }          
        }
      } catch (error) {
        console.log(error);
      }
    };
    obtenerDatos();
  }, [usuario, dataMacCan]);

  const getDataFromDB = async (user, uid, fIni, fFin, tipo = "modulo") => {
    let valArray = [];
    if (tipo === "modulo") {
      const getDataSetValues = functions.httpsCallable("getDataSetValues");
      const values = await getDataSetValues({
        user: user,
        uid: uid.trim(),
        dateStart: fIni,
        dateFinish: fFin,
      });

      let data = values.data.result[0];
      for (let i in data) {
        let newEntry = [Date.parse(data[i].timestamp.value), data[i].val];
        valArray.push(newEntry);
      }
    } else if (tipo === "manual") {
      const getDataSetValues = functions.httpsCallable(
        "getDataSetValuesBinacle"
      );
      valArray = await getDataSetValues({
        user: user,
        uid: uid.trim(),
        dateStart: fIni,
        dateFinish: fFin,
      });
    }
    //console.log(valArray)
    return valArray;
  };

  return (
    <div>
      <GraphicDataDevices
        inputDevices={inputDevices}
        outDevices={outDevices}
        manualDevices={manualDevices}
        getDataFromDB={getDataFromDB}
      />
    </div>
  );
};

export default Graph;
export { Graph };
