import React, { useContext, useEffect, useState } from 'react'
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, InputLabel, makeStyles, MenuItem, Select, TextField } from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons';
import { MultiCropContext } from '../../../../context/MultiCropContext/MultiCropContext';
import { UserContext } from '../../../../context/UserProvider';
import { db } from '../../../../config/firebase';


const useStyles = makeStyles((theme) => ({
	dialogPaper: {
	  minWidth: '50vw', // Asegura un mínimo de ancho para el diálogo
	  maxWidth: '50vw', // Un máximo para evitar que sea demasiado grande
	  [theme.breakpoints.down('xs')]: {
		minWidth: '80vw', // Para pantallas pequeñas, ocupa más espacio
	  },
	},
	closeButton: {
	  position: 'absolute',
	  right: theme.spacing(1),
	  top: theme.spacing(1),
	  color: theme.palette.grey[500],
	},
	root: {
		'& .MuiTextField-root': {
		  margin: theme.spacing(1),
		  
		},
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: "30ch",
		boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
	},
	formControl: {
		margin: theme.spacing(1),
		position: 'relative',
		width: "50%",
		boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
		[theme.breakpoints.down('sm')]: {
			width: "100%", // Ocupa todo el ancho en pantallas pequeñas
		},
	},
	button: {
		display: 'block',
		marginTop: theme.spacing(2),
		marginLeft: theme.spacing(2)
	},
}));

export const EditCropConfig = ({openPopup, handleClosePopup}) => {
	const classes = useStyles();
	const { usuario } = useContext(UserContext)
	const { namesOfCrops, tabValue,weeksOfCrops,daysOfCrops,idOfCrops,placeOfCrops,crops,
		setWeeksOfCrops,setDaysOfCrops,setNamesOfCrops,sendToCRcrop,setTabValue,setCrops,setPlaceOfCrops
	} = useContext(MultiCropContext);
	const [namesEdited, setNamesEdited] = useState([])
	const [weeksEdited, setWeeksEdited] = useState([])
	const [daysEdited, setDaysEdited] = useState([])
	const [cropsEdited, setCropsEdited] = useState([])
	const [placesEdited, setPlacesEdited] = useState([])
	const [isLoadingDelete, setIsLoadingDelete] = useState(false)
	const [isLoadingEdit, setIsLoadingEdit] = useState(false)

	const handleNameChange = (event) => {
		const newName = event.target.value;
		const newArrayNames = [...namesEdited]
		newArrayNames[tabValue] = newName
		setNamesEdited([...newArrayNames])
	}

	const handleCropChange = (event) => {
		const newCrop = event.target.value;
		const newArrayCrops = [...cropsEdited]
		newArrayCrops[tabValue] = newCrop
		setCropsEdited([...newArrayCrops])
	}

	const handleWeekChange = (event) => {
		const numberValue = event.target.value
		if((parseInt(numberValue) >= 0 && parseInt(numberValue) < 53) || numberValue === "") {
			const newWeeksArray = [...weeksEdited]
			newWeeksArray[tabValue] = numberValue
			setWeeksEdited([...newWeeksArray])
		}
	}

	const handleDayChange = (event) => {
		const numberValue = event.target.value
		if((Number(numberValue) >= 0 && Number(numberValue) < 8) || numberValue === "") {
			const newDaysArray = [...daysEdited]
			newDaysArray[tabValue] = numberValue
			setDaysEdited([...newDaysArray])
		}
	}

	const handlePlaceChange = (event) => {
		const newPlace = event.target.value;
		const newArrayPlaces = [...placesEdited]
		newArrayPlaces[tabValue] = newPlace
		setPlacesEdited([...newArrayPlaces])
	}

	const handleDeleteCrop = async () => {
		setIsLoadingDelete(true)
		const data = {
			username: usuario.username,
			action: "deleteCrop",
			index: tabValue
		}
		const response = await sendToCRcrop(data)
		if(response === "allOK") {
			const arrayOfNames = [...namesOfCrops];
			arrayOfNames.splice(tabValue,1)
			setNamesOfCrops([...arrayOfNames])
			setTabValue(0)
			handleClosePopup()
		}
	}

	const saveChanges = async () => {
		try {
			const addr = usuario.username + "/multiCrop/" + idOfCrops[tabValue];
			const docRef = db.collection(addr).doc('data');
			const dataToSave = {
				cropName: namesEdited[tabValue],
				cultivationOf: cropsEdited[tabValue],
				growingDay: daysEdited[tabValue],
				growingWeek: weeksEdited[tabValue],
				place: placesEdited[tabValue]
			}
			await docRef.set({...dataToSave})
			setNamesOfCrops([...namesEdited])
			setCrops([...cropsEdited])
			setWeeksOfCrops([...weeksEdited])
			setDaysOfCrops([...daysEdited])
			setPlaceOfCrops([...placesEdited])
			handleClosePopup()

		} catch (error) {
			console.error("Error en editar datos de cultivo:",error)
		}
	}

	//Aqui se cargan los datos actuales del multicultivo
	useEffect(() => {
		setNamesEdited([...namesOfCrops])
		setCropsEdited([...crops])
		setWeeksEdited([...weeksOfCrops])
		setDaysEdited([...daysOfCrops])
		setPlacesEdited([...placeOfCrops])
	}, [namesOfCrops,daysOfCrops,weeksOfCrops,placeOfCrops,crops])

	//Aqui se pasan los nuevos datos editados una vez que el popup se cierra
	useEffect(() => {
		if(!openPopup) {
			setNamesEdited([...namesOfCrops])
			setCropsEdited([...crops])
			setWeeksEdited([...weeksOfCrops])
			setDaysEdited([...daysOfCrops])
			setPlacesEdited([...placeOfCrops])
			setIsLoadingDelete(false)
		}
	}, [openPopup])
	
  return (
	<div>
		<Dialog
		open={openPopup}
        onClose={handleClosePopup}
        classes={{ paper: classes.dialogPaper }} 
		>
			<DialogTitle id="Edita-cultivo">{"Editar Cultivo"}
				<IconButton aria-label="close" className={classes.closeButton} onClick={handleClosePopup}>
				<CloseOutlined />
				</IconButton>
			</DialogTitle>

			<DialogContent>
				<div style={{marginTop: "5px", marginBottom: "10px"}}> 
					<Divider />
				</div>

				<Grid container justifyContent='center' alignItems='center'>
					
					{/* <label className={"mt-3"}>Nombre del Multicultivo:</label> */}
					<TextField 
					id="name-crop" 
					variant="outlined" 
					size="small"
					margin="dense"
					label="Nombre del cultivo"
					//defaultValue={namesOfCrops[tabValue]}
					value={namesEdited[tabValue]}
					onChange={handleNameChange}
					className={classes.textField}
					/>
				
					{/* <label className={"mt-3"}>Tipo de cultivo:</label> */}
					<TextField 
					id="crop-type" 
					variant="outlined" 
					size="small"
					margin="dense"
					label="Tipo de cultivo"
					value={cropsEdited[tabValue]}
					onChange={handleCropChange}
					className={classes.textField}
					/>
					
					
					{/* <label className={"mt-3"}>Semana:</label> */}
					<TextField 
					id="week-crop" 
					size="small"
					margin="dense"
					label="Semana"
					//defaultValue={weeksOfCrops[tabValue]}
					value={weeksEdited[tabValue]}
					type='number'
					variant="outlined" 
					onChange={handleWeekChange}
					className={classes.textField}
					inputProps={{ min: 0, max: 53 }}
					/>

					{/* <label className={"mt-3"}>Día:</label> */}
					<TextField 
					id="day-crop" 
					variant="outlined" 
					size="small"
					margin="dense"
					label="Día"
					//defaultValue={daysOfCrops[tabValue]}
					value={daysEdited[tabValue]}
					type='number'
					onChange={handleDayChange}
					className={classes.textField}
					inputProps={{ min: 0, max: 7 }}
					/>
					
					{/* <label className={"mt-3"}>Lugar de cultivo:</label> */}
					<FormControl variant="outlined" className={classes.formControl}>
						<InputLabel id="demo-simple-select-outlined-label">Entorno de cultivo</InputLabel>
						<Select
						labelId="enviro-crop"
						id="envCrop"
						value={placesEdited[tabValue]}
						onChange={handlePlaceChange}
						label="Entorno de cultivo"
						labelWidth={1}
						size="small"
						>
						<MenuItem value={"Interior"}>Interior</MenuItem>
						<MenuItem value={"Exterior"}>Exterior</MenuItem>
						</Select>
					</FormControl>
					{/* <TextField 
					id="crop-place" 
					variant="outlined" 
					size="small"
					margin="dense"
					label="Entorno del cultivo"
					value={placesEdited[tabValue]}
					onChange={handlePlaceChange}
					className={classes.textField}
					/> */}
					
				</Grid>
				<div style={{marginTop: "15px", marginBottom: "15px"}}> 
					<Divider />
				</div>

				<Grid container justifyContent='center' alignItems='center'>
					
					
					{isLoadingEdit ? <CircularProgress color="primary" />
					: 
					<Button 
					variant="contained" 
					color="primary" 
					className={classes.button}	
					onClick={saveChanges}
					disabled={namesEdited[tabValue] === "" || daysEdited[tabValue] === "" || 
						weeksEdited[tabValue] === "" || isLoadingDelete}
					>
						Editar
					</Button>
					}
				</Grid>
			</DialogContent>
		</Dialog>
	</div>
  )
}
